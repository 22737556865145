import React from 'react';
import clone from 'clone';
import actions from './actions';
import message from "../../components/feedback/message";
import MessageContent from "../../components/uielements/styles/message.style";

const initState = {
  init: false,
  current: {},
  editable: {},
  isNew: false,
  mode: 'view'
};




export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.UPDATE: {
      const current = action.entry
        ? action.entry
        : state.current;
      return {
        ...state,
        current: clone(current),
        init: true,
        isNew: false,
        enableEditView: false
      };
    }
    case actions.SELECT_CURRENT_SUCESS: {

      //const state = action.state['product_store']
      //const list = state.list;
      //const index = list.map(entry => entry._id).indexOf(action._id);
      const isNew = (action.data ? false : true);
      const current = !action.data
        ? {
            _id: action._id,
            number: `#${action._id}`,
            key: action._id,
            //...newInvoice
          }
        : action.data;
      const enableEditView = isNew;

      
      return {
        ...state,
        current,
        isNew,
        enableEditView,
        editable: clone(current)
      };
    }
    case actions.UPDATE_SUCESS:
      {

        const s = action.state['product_store']

        s.entities[action.data._id] = action.data
       
        message.success(
          <MessageContent>
            Daten erfolgreich gespeichert
          </MessageContent>,
          3
        );

        return {
          ...state,
          current: action.data == null ? initState.current : action.data,
        };
      }
      case actions.DELETE_SUCESS:
      {

        const s = action.state['product_store']

        if ( s.entities[action.data._id])
        {
          delete  s.entities[action.data._id]
        }
       
        message.success(
          <MessageContent>
            Daten erfolgreich gelöscht
          </MessageContent>,
          3
        );

        return {
          ...state
        };
      }
    case actions.CREATE_SUCESS:
      {

        const s = action.state['product_store']

        s.entities[action.data._id] = action.data

        message.success(
          <MessageContent>
            Daten erfolgreich gespeichert
          </MessageContent>,
          3
        );

        return {
          ...state,
          current: action.data === null ? initState.current : action.data,
        };
      }
      case actions.UPDATE_ERROR:
      case actions.CREATE_ERROR:
      case actions.DELETE_ERROR:
        {
          message.error(
            <MessageContent>
              {action.data}
            </MessageContent>,
            10
          );

          return state;
        }
    default:
      return state;
  }
}
