const DOCUMENT = 'PRICE_';

const actions = {
  GET_LIST: DOCUMENT+'GET_LIST',
  UPDATE: DOCUMENT+'UPDATE',
  UPDATE_SAGA: DOCUMENT+ 'UPDATE_SAGA',
  SELECT_CURRENT: DOCUMENT+'SELECT_CURRENT',
  
  TOGGLE_MODAL: DOCUMENT+'TOGGLE_MODAL',

  initData: productId => { return { type: actions.GET_LIST, productId } },
  
  selectCurrent: _id => {return { type: actions.SELECT_CURRENT, _id }},
  toggleModal: (data = null) => ({
    type: actions.TOGGLE_MODAL,
    payload: { data },
  }),
  
};
export default actions;
