import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import axios from 'axios';


function* getList(data) {
  try {
    
    const response = yield call(axios.get, '/api/platform');
    yield put({ type: actions.UPDATE_LIST, list: response.data  });

  } catch (e) {
    yield put({ type: actions.UPDATE_ERROR, e });
  }
}


export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.GET_LIST, getList)
  ]);
}
