import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import actions from './actions';
import axios from 'axios';

const localDataName = 'priceRules';

function* getData(data) {
  try {
    const response = yield call(axios.get, '/api/product/' + data.parent._id + '/offer');
    yield put({ type: actions.GET_LIST_SUCESS, list: response.data, parent: data.parent });

  } catch (e) {
    yield put({ type: actions.GET_LIST_ERROR, e });
  }
}

const getCurrent = state => state.PriceRules;


function* updateData(entry) {
  try {


    const res = yield call(axios.get, '/api/offer/' + entry._id + '/update', entry)

    yield put({ type: actions.UPDATE_SUCESS, data: res.data });


  } catch (e) {
    yield put({ type: actions.UPDATE_ERROR, e });
  }
}

function* updateAll(entry) {
  try {


     yield call(axios.get, '/api/product/' + entry._id + '/offer/update')

    yield put({ type: actions.UPDATE_SUCESS, data: {} });
    yield put({ type: actions.GET_LIST });

  } catch (e) {
    yield put({ type: actions.UPDATE_ERROR, e });
  }
}

function* saveData(entry) {

  const state = yield select(getCurrent)

  if (!state.isNew && state.current) {
    yield call(updateData, {
      ...state.current,
      ...entry.entry,
    })
  }
  else {
    //yield call(createData, entry.entry)
  }
}

function* deleteData(data) {
  try {

    yield call(axios.delete, '/api/offer/' + data._id + '')

    yield put({ type: actions.DELETE_SUCESS, data: data._id });

  } catch (e) {
    yield put({ type: actions.DELETE_ERROR, e });
  }
}

function* cancelData(data) {
  try {

    const res = yield call(axios.get, '/api/offer/' + data._id + '/cancel')

    yield put({ type: actions.CANCEL_SUCESS, data: res.data });

  } catch (e) {
    yield put({ type: actions.CANCEL_ERROR, e });
  }
}


export function* updateSaga({ products, product }) {
  yield localStorage.setItem(localDataName, JSON.stringify(products));
  yield put({
    type: actions.UPDATE,
    products,
    product
  });
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.GET_LIST, getData),
    yield takeEvery(actions.UPDATE_SAGA, updateSaga),
    yield takeEvery(actions.SAVE, saveData),
    yield takeEvery(actions.DELETE, deleteData),
    yield takeEvery(actions.CANCEL, cancelData),
    yield takeEvery(actions.UPDATE, updateData),
    yield takeEvery(actions.UPDATE_ALL, updateAll)
  ]);
}
