import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { getToken, clearToken } from '../../helpers/utility';
import actions from './actions';


import axios from 'axios';
import { Ability } from '@casl/ability';

const jwt = require('jsonwebtoken')
const { unpackRules } = require('@casl/ability/extra')


export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function*(payload) {

    try {
      const res = yield axios.post('/api/auth', payload.user)

      const token = jwt.decode(res.data.token)
      
       yield put({
        type: actions.LOGIN_SUCCESS,
        token: res.data.token,
        ability: new Ability(unpackRules(token.rules)),
        profile: 'Profile'
      });

    } catch (e) {
      alert('Error logging in please try again');
      yield put({ type: actions.LOGIN_ERROR });
    }

  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {

    yield axios.defaults.headers.common['Authorization'] = `Bearer ${payload.token}` 

    yield localStorage.setItem('id_token', payload.token);
  });
} 

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    yield delete axios.defaults.headers.common["Authorization"];
    clearToken();
    yield put(push('/'));
  });
}
export function* checkAuthorization() {
  //yield takeEvery(actions., function*() {
    const token = getToken().get('idToken');
    if (token) {
      const decode = jwt.decode(token)

      try {

        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        yield axios.post('/api/session')

        yield put({
          type: actions.LOGIN_SUCCESS,
          token,
          ability: decode ? new Ability(unpackRules(decode.rules)) : new Ability(),
          profile: 'Profile'
        });
      }catch(e)
      {
        //alert(e);
        yield put({ type: actions.LOGIN_ERROR });
      }     
    
      
    }

    yield put({
      type: actions.LOGIN_INIT
    });
  

  //}
  //);
}
export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.CHECK_AUTHORIZATION, checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout)
  ]);
}
