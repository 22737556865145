
import actions from './actions';

const initState = {
  entries: [],
  init: false,
  list: []
};


export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.UPDATE_LIST: {

      
      action.list.forEach(element => {
        state.entries[element._id] = element;
      });

      return {
        ...state,
        list: action.list.map(e => e._id ),
        init: true
      };
    }
    default:
      return state;
  }
}
