const DOCUMENT = 'OFFER_';

const actions = {
  GET_LIST: DOCUMENT + 'GET_LIST',
  GET_LIST_SUCESS: DOCUMENT + 'GET_LIST_SUCESS',
  GET_LIST_ERROR: DOCUMENT + 'GET_LIST_ERROR',
  UPDATE: DOCUMENT + 'UPDATE',
  UPDATE_ALL: DOCUMENT + 'UPDATE_ALL',
  UPDATE_SAGA: DOCUMENT + 'UPDATE_SAGA',
  SELECT_CURRENT: DOCUMENT + 'SELECT_CURRENT',
  SAVE: DOCUMENT + 'SAVE',
  DELETE: DOCUMENT + 'DELETE',
  DELETE_SUCESS: DOCUMENT + 'DELETE_SUCESS',
  DELETE_ERROR: DOCUMENT + 'DELETE_ERROR',
  CANCEL: DOCUMENT + 'CANCEL',
  CANCEL_SUCESS: DOCUMENT + 'CANCEL_SUCESS',
  CANCEL_ERROR: DOCUMENT + 'CANCEL_ERROR',
  UPDATE_SUCESS: DOCUMENT + 'UPDATE_SUCESS',
  UPDATE_ERROR: DOCUMENT + 'UPDATE_ERROR',
  TOGGLE_MODAL: DOCUMENT + 'TOGGLE_MODAL',

  initData: parent => { return { type: actions.GET_LIST, parent } },

  selectCurrent: _id => { return { type: actions.SELECT_CURRENT, _id } },
  toggleModal: (data = null) => ({
    type: actions.TOGGLE_MODAL,
    payload: { data },
  }),
  onSubmit: entry => ({ type: actions.SAVE, entry }),
  delete: _id => ({ type: actions.DELETE, _id }),
  cancel: _id => ({ type: actions.CANCEL, _id }),
  update: _id => (
    { type: actions.UPDATE, _id }
  ),
  updateAll: _id => (
    { type: actions.UPDATE_ALL, _id }
  ),
};
export default actions;
