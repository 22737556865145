import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import actions from './actions';
import axios from 'axios';


const getCurrent = state => state.Category;


function* getRoot(data) {
  try {

    
    const response = yield call(axios.get, '/api/category');
    yield put({ type: actions.UPDATE_ROOT, list: response.data  });

  } catch (e) {
    yield put({ type: actions.UPDATE_ERROR, e });
  }
}

function* getChildren(data) {
  try {

    const state = yield select(getCurrent)

    const entry = state.entries[data._id];

    if (!entry || entry.loaded)
    {
      return
    }

    const response = yield call(axios.get, '/api/category/'+data._id+'');
    yield put({ type: actions.UPDATE_CHILDREN, list: response.data, _id: data._id  });

  } catch (e) {
    yield put({ type: actions.UPDATE_ERROR, e });
  }
}

function* getEntry(data) {
  try {

    const state = yield select(getCurrent)

    if (state.entries[data._id])
    {
        return;
    }
    console.log(data._id);
    const response = yield call(axios.get, '/api/category/'+data._id+'?parentTree');
    console.log(response);
    yield put({ type: actions.UPDATE_ENTRY, list: response.data, _id: data._id  });

  } catch (e) {
    console.log(e);
    yield put({ type: actions.UPDATE_ERROR, e });
  }
}


export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.GET_CHILDREN, getChildren),
    yield takeEvery(actions.GET_ENTRY, getEntry),
    yield takeEvery(actions.GET_LIST, getRoot)
  ]);
}
