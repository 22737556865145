import Auth from './auth/reducer';
import App from './app/reducer';
//import Product from './product/reducer';
import Invoices from './invoice/reducer';
import Products from './product/reducer';
import Prices from './price/reducer';
import PriceRules from './priceRule/reducer';
import Stores from './store/reducer';
import PriceSource from './priceSource/reducer';
import Offers from './offer/reducer';
import Category from './category/reducer';
import ProductPages from './product/pagination';
import Platform from './platform/reducer';
import User from './user/reducer';
import Merchant from './merchant/reducer';
export default {
  Auth,
  App,
  Invoices,
  Products,
  [ProductPages.storeName]: ProductPages.reducers,
  Prices,
  PriceRules,
  Stores,
  PriceSource,
  Offers,
  Category,
  Platform,
  User,
  Merchant
};
