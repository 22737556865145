const DOCUMENT = 'CATEGORY_';

const actions = {
  GET_LIST: DOCUMENT+'GET_LIST',
  GET_CHILDREN: DOCUMENT+'GET_CHILDREN',
  GET_ENTRY: DOCUMENT+'GET_ENTRY',

  UPDATE_CHILDREN: DOCUMENT+'UPDATE_CHILDREN',
  UPDATE_ENTRY: DOCUMENT+'UPDATE_ENTRY',
  UPDATE_ROOT: DOCUMENT+'UPDATE_ROOT',
  UPDATE_ERROR: DOCUMENT+'UPDATE_ERROR',

  initData: () => { return { type: actions.GET_LIST } },

  loadChildren: _id => { return { type: actions.GET_CHILDREN, _id } },
  loadEntry: _id => { return { type: actions.GET_ENTRY, _id } },
};
export default actions;
