
import { createPaginator } from 'redux-cached-pagination';
import { normalize, schema } from 'normalizr';



import axios from 'axios';
const config = {
  refreshResultInBackground: false,
  timeToRefresh: 1000, //[ms]
  searchHistoryLength: 5,
  elementsPerPage: 50
};
const searchParamsInitState = { searchPhrase: '' };


const entity = new schema.Entity('entity', {}, { idAttribute: '_id' });
const responseSchema = new schema.Array(entity);;

const callExaminationsApi = (page, requestParams, dispatch) => {
  const { searchPhrase } = requestParams;

  return new Promise(async (resolve, reject) => {
    const response = await axios.get('/api/product', { params: { page: page, ...requestParams } });


    const normalizedData = normalize(response.data.docs, responseSchema);

    //const serverResponse = provider.getExaminationsPage(page, ELEMENTS_PER_PAGE, searchPhrase);
    //const formattedResponse = normalizeResponse(serverResponse);
    // dispatch({
    //   type: globalEntitiesActionNames.entitiesAdded,
    //   entities: formattedResponse.entities
    // });
    const ress = {
      totalElements: response.data.totalDocs,
      elements: normalizedData.result,
      entities: normalizedData.entities.entity ? normalizedData.entities.entity : []
    };
    resolve(ress);
  });
};

const paginator = createPaginator('product', callExaminationsApi, config, searchParamsInitState);


//export used methods
export const requestPage = paginator.requestPage;
export const reducers = paginator.reducers;
export const storeName = paginator.storeName;
export const updateSearchParams = paginator.updateSearchParams;

export const setCurrentPage = paginator.setCurrentPage;
export const getCurrentPage = paginator.selectors.getCurrentPage;

export const getPageElements = config.elementsPerPage;

//selectors:
export const getTotalElements = paginator.selectors.getTotalElements;
export const getPage = paginator.selectors.getPage;
export const getSearchParams = paginator.selectors.getSearchParams;

export default paginator;