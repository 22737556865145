import actions from './actions';

const { Ability } = require('@casl/ability')

const initState = { init: false, idToken: null, ability: new Ability() };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return { ...state, idToken: action.token, ability: action.ability };
      case actions.LOGIN_INIT:
      return { ...state, init: true };
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
