import React from 'react';
import clone from 'clone';
import actions from './actions';
import { size } from 'styled-theme/dist';
import message from "../../components/feedback/message";
import MessageContent from "../../components/uielements/styles/message.style";


const initState = {
  entries: [],
  list: [],
  init: false,
  current: {},
  editable: {},
  isNew: false,
  mode: 'view',
  modalActive: false,
};



export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.UPDATE: {

      action.list.forEach(element => {
        state.entries[element._id] = element;
      });

      const current = action.entry
        ? action.entry
        : state.current;
      return {
        ...state,
        list: action.list,
        current: clone(current),
        init: true,
        isNew: false,
        enableEditView: false
      };
    }
    case actions.SELECT_CURRENT: {

      const list = state.list;
      const index = list.map(entry => entry._id).indexOf(action._id);
      const isNew = index === -1;
      const current = isNew
        ? {
          _id: action._id,
          number: `#${action._id}`,
          key: action._id,
          //...newInvoice
        }
        : list[index];
      const enableEditView = isNew;
      return {
        ...state,
        current,
        isNew,
        enableEditView,
        editable: clone(current)
      };
    }
    case actions.TOGGLE_MODAL:

      return {
        ...state,
        modalActive: !state.modalActive,
        current: action.data == null ? initState.current : action.data,
      };
    case actions.UPDATE_SUCESS:
      {
        const blub = state.list;
        const index = blub.map(entry => entry._id).indexOf(action.data._id);

        state.entries[action.data._id] = action.data;
        if (index ==! -1) {
          blub[index] = action.data;
        }
        message.success(
          <MessageContent>
            Daten erfolgreich gespeichert
          </MessageContent>,
          3
        );

        return {
          ...state,
          current: action.data === null ? initState.current : action.data,
        };
      }
      case actions.DELETE_SUCESS:
      {
        const blub = state.list;
        const index = blub.map(entry => entry._id).indexOf(action._id);

        if (index ==! -1) {
          delete blub[index];  
        }
        message.success(
          <MessageContent>
            Daten erfolgreich gelöscht
          </MessageContent>,
          3
        );

        return {
          ...state
        };
      }
    case actions.CREATE_SUCESS:
      {
        const list = state.list;
        const index = list.map(entry => entry._id).indexOf(action.data._id);

        state.entries[action.data._id] = action.data;
        if (index === -1) {
          list[size(list)] = action.data;
        }
        else {
          list[index] = action.data;
        }

        message.success(
          <MessageContent>
            Daten erfolgreich gespeichert
          </MessageContent>,
          3
        );

        return {
          ...state,
          current: action.data === null ? initState.current : action.data,
        };
      }
      case actions.UPDATE_ERROR:
      case actions.CREATE_ERROR:
      case actions.UPDATE_ERROR:
        {
          message.error(
            <MessageContent>
              {action.data}
            </MessageContent>,
            10
          );

          return state;
        }
    default:
      return state;
  }
}
