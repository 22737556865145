import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { connect } from "react-redux";
import App from "./containers/App/App";
import asyncComponent from "./helpers/AsyncFunc";
import { Spin } from 'antd';
import Loader from './components/utility/loader';

const RestrictedRoute = ({ component: Component, isLoggedIn, isAuthInit, ...rest }) => (
  <Route
    {...rest}
    render={props => {

          if (isLoggedIn)
          {
            return (
              <Component {...props} />
            )
          }

          if (!isAuthInit)
          {
            return ( <Loader /> )
          }

          return (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: props.location }
              }}
            />
          )


        }
      
    }
  />
);
const PublicRoutes = ({ history, isLoggedIn, isAuthInit }) => {
  return (
    <ConnectedRouter history={history}>
      <div>
        <Switch>
        <Route
          exact
          path={"/"}
          component={asyncComponent(() => import("./containers/Page/signin"))}
        />
        
        <Route
          exact
          path={"/500"}
          component={asyncComponent(() => import("./containers/Page/500"))}
        />
        <Route
          exact
          path={"/signin"}
          component={asyncComponent(() => import("./containers/Page/signin"))}
        />
        <Route
          exact
          path={"/signup"}
          component={asyncComponent(() => import("./containers/Page/signup"))}
        />
         <Route
          exact
          path={"/disclaimer"}
          component={asyncComponent(() => import("./containers/Page/disclaimer"))}
        />
        <Route
          exact
          path={"/forgotpassword"}
          component={asyncComponent(() =>
            import("./containers/Page/forgotPassword")
          )}
        />
        <Route
          exact
          path={"/resetpassword"}
          component={asyncComponent(() =>
            import("./containers/Page/resetPassword")
          )}
        />

        <RestrictedRoute
          path="/dashboard"
          component={App}
          isLoggedIn={isLoggedIn}
          isAuthInit={isAuthInit}
        />

        <Route
          component={asyncComponent(() => import("./containers/Page/404"))}
        />
        </Switch>
      </div>
    </ConnectedRouter>
  );
};

export default connect(state => ({
  isLoggedIn: state.Auth.idToken !== null,
  isAuthInit: state.Auth.init,
  blub:true
}))(PublicRoutes);
