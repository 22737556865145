import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
  {
    path: '',
    component: asyncComponent(() => import('../dashboard')),
  },
  {
    path: 'blankPage',
    component: asyncComponent(() => import('../blankPage')),
  },
  {
    path: 'authCheck',
    component: asyncComponent(() => import('../AuthCheck')),
  },
  {
    path: 'product/:productid',
    component: asyncComponent(() => import('../Product/singleProduct')),
  },
  {
    path: 'product',
    component: asyncComponent(() => import('../Product')),
  },

  {
    path: 'invoice',
    component: asyncComponent(() => import('../Invoice')),
  },
  {
    path: 'priceRule',
    component: asyncComponent(() => import('../PriceRule')),
  },
  {
    path: 'priceRule/:priceRule',
    component: asyncComponent(() => import('../PriceRule/priceRule')),
  },
  {
    path: 'store',
    component: asyncComponent(() => import('../Store')),
  },
  {
    path: 'store/:store',
    component: asyncComponent(() => import('../Store/store')),
  },
  {
    path: 'priceSource',
    component: asyncComponent(() => import('../PriceSource')),
  },
  {
    path: 'priceSource/:priceSource',
    component: asyncComponent(() => import('../PriceSource/priceSource')),
  },
  {
    path: 'user',
    component: asyncComponent(() => import('../User/user')),
  },
  {
    path: 'merchant',
    component: asyncComponent(() => import('../Merchant/merchant')),
  },
  {
    path: 'settings',
    component: asyncComponent(() => import('../Merchant/merchant')),
  },
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        {routes.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default AppRouter;
