import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import actions from './actions';
import axios from 'axios';
import { push } from 'react-router-redux';

const localDataName = 'priceRules';

function* getData() {
  try {
    const response = yield call(axios.get, '/api/user');
    yield put({ type: actions.UPDATE, list: response.data });

  } catch (e) {
    yield put({ type: actions.UPDATE_PRICE_ERROR, e });
  }
}

const getCurrent = state => state.PriceRules;


function* createData(entry) {
  try {

    const res = yield call(axios.post, '/api/user', entry)

    yield put({ type: actions.CREATE_SUCESS, data: res.data });
    yield put(push(`/dashboard/user/${res.data._id}`))
    
  } catch (e) {
    yield put({ type: actions.CREATE_ERROR, e });
  }
}

function* updateData(entry) {
  try {


    const res = yield call(axios.put, '/api/user/'+entry._id, entry)

    yield put({ type: actions.UPDATE_SUCESS, data: res.data });

  } catch (e) {
    yield put({ type: actions.UPDATE_ERROR, e });
  }
}


function* saveData(entry) {

  const state = yield select(getCurrent)

  if (!state.isNew && state.current) {
    yield call(updateData, {
      ...state.current,
      ...entry.entry,
    })
  }
  else {
    yield call(createData, entry.entry)
  }
}

function* deleteData(data) {
  try {

    const res = yield call(axios.delete, '/api/user/'+data._id)

    yield put({ type: actions.DELETE_SUCESS, data: data._id });

  } catch (e) {
    yield put({ type: actions.DELETE_ERROR, e });
  }
}


export function* updateSaga({ products, product }) {
  yield localStorage.setItem(localDataName, JSON.stringify(products));
  yield put({
    type: actions.UPDATE,
    products,
    product
  });
}
export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.GET_LIST, getData),
    yield takeEvery(actions.UPDATE_SAGA, updateSaga),
    yield takeEvery(actions.SAVE, saveData),
    yield takeEvery(actions.DELETE, deleteData)
  ]);
}
