const options = [
  {
    key: 'dash',
    label: 'Dashboard',
    leftIcon: 'ion-ios-pulse-strong',
  },
  {
    key: 'product',
    label: 'Produkte',
    leftIcon: 'ion-ios-book-outline',
    right: 'Product'
  },

  {
    key: 'prices',
    label: 'Preise',
    leftIcon: 'ion-cash',
    right: 'prices',
    children: [
      {
        key: 'priceRule',
        label: 'Regeln',
      },
      {
        key: 'priceSource',
        label: 'Quelle',
      },
      {
        key: 'priceTrend',
        label: 'Verlauf',
      },
    ],
  },
  {
    key: 'orders',
    label: 'Bestellungen',
    leftIcon: 'ion-bag',
    children: [
      {
        key: 'invoices',
        label: 'Rechnungen',
      },
      {
        key: 'categories',
        label: 'Kategorien',
        leftIcon: 'ion-ios-pricetags',
      },
    ],
  },
  {
    key: 'reports',
    label: 'Auswertungen',
    leftIcon: 'ion-arrow-graph-up-right',
    children: [
      {
        key: 'report-prices',
        label: 'Preise',
      },
      {
        key: 'report-inventory',
        label: 'Bestand',
      },
    ],
  },
  {
    key: 'settings-group',
    label: 'Einstellungen',
    leftIcon: 'ion-ios-settings-strong',
    children: [
      {
        key: 'settings',
        label: 'Platform',
      },
      {
        key: 'store',
        label: 'Stores',
      },
      
    ],
  },
];
export default options;
