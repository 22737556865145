import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import axios from 'axios';

const localDataName = 'mateProdcuts';


const getApi = productid => axios.get('http://localhost:3001/products/'+productid+'/prices');

function* getData({ productId }) {
   try {
      const response = yield call(getApi, productId);
       yield put({type: actions.UPDATE, list: response.data, currentProductId: productId });

    } catch (e) {
      yield put({type: actions.UPDATE_PRICE_ERROR, e });
    }
  }


export function* updateSaga({ products, product }) {
  yield localStorage.setItem(localDataName, JSON.stringify(products));
  yield put({
    type: actions.UPDATE_PRODUCT,
    products,
    product
  });
}
export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.GET_LIST, getData),
    yield takeEvery(actions.UPDATE_SAGA, updateSaga)
  ]);
}
