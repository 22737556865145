const DOCUMENT = 'PLATFORM_';

const actions = {
  GET_LIST: DOCUMENT+'GET_LIST',

  UPDATE_LIST: DOCUMENT+'UPDATE_LIST',
  UPDATE_ERROR: DOCUMENT+'UPDATE_ERROR',
 

  initData: () => { return { type: actions.GET_LIST } },


};
export default actions;
