import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import actions from './actions';
import categoryAction from '../category/actions'
import axios from 'axios';
import { push, replace  } from 'react-router-redux';

import { storeName } from './pagination'

const localDataName = 'mateProdcuts';


function* getData(data) {
  try {
    const response = yield call(axios.get, '/api/product');
    yield put({ type: actions.UPDATE, list: response.data });

  } catch (e) {
    yield put({ type: actions.UPDATE_PRICE_ERROR, e });
  }
}

function* updateEntry(data) {
  try {
    const response = yield call(axios.get, '/api/product/'+data._id);

     yield put({ type: actions.UPDATE_ENTRY_SUCESS, entry: response.data });

  } catch (e) {
    yield put({ type: actions.UPDATE_ENTRY_ERROR, e });
  }
}



  const getCurrent = state => state.Products;

  const getPageStore = state => state['product_store'];

  function* createData(entry) {
    try {
  
      const res = yield call(axios.post, '/api/product', entry)
  
      const state = yield select(state => state)
      yield put({ type: actions.CREATE_SUCESS, data: res.data, state });
     //Workaround
      window.location = `/dashboard/product/${res.data._id}`;
      
      yield put(replace(`/dashboard/product/${res.data._id}`))
      
    } catch (e) {
      yield put({ type: actions.CREATE_ERROR, e });
    }
  }
  
  function* updateData(entry) {
    try {
  
  
      const res = yield call(axios.put, '/api/product/'+entry._id, entry)

   const state = yield select(state => state)
      yield put({ type: actions.UPDATE_SUCESS, data: res.data, state });
  
    } catch (e) {
      yield put({ type: actions.UPDATE_ERROR, e });
    }
  }
  
  
  function* saveData(entry) {
  
    const state = yield select(getCurrent)
  
    if (!state.isNew && state.current) {
      yield call(updateData, {
        ...state.current,
        ...entry.entry,
      })
    }
    else {
      yield call(createData, {
        ...state.current,
        ...entry.entry,
      })
    }
  }


   function* selectCurrent(entry) {
  
    const productStaute = yield select(getCurrent)
    const state = yield select(getPageStore)
    
    var e = state.entities[entry._id];


    if (entry._id.indexOf("new") >= 0)
    {
      //yield put({ type: actions.SELECT_CURRENT_FAILED, id: entry._id });
      yield put({ type: actions.SELECT_CURRENT_SUCESS, data: undefined, _id: entry._id  });
    return     
    }

    if (e)
    {

      if (e.categories)
      {
        for(var i = 0; i< e.categories.length; i++)
        {
          yield put({ type: categoryAction.GET_ENTRY, _id: e.categories[i]  });
        }

      }

      try
      {
        if (!e.priceInfo)
        {
          const res = yield call(axios.get, '/api/product/'+entry._id);
  
          state.entities[entry._id].priceInfo = res.data.priceInfo;
        }
      }catch(err)
      {
        //ignore
      }
      


      yield put({ type: actions.SELECT_CURRENT_SUCESS, data: state.entities[entry._id] });
      return
    }

      try {
   
  
        const res = yield call(axios.get, '/api/product/'+entry._id);

        state.entities[entry._id] = res.data;

        if (res.data.categories)
        {
          for(var i = 0; i< res.data.categories.length; i++)
          {
            yield put({ type: categoryAction.GET_ENTRY, _id: res.data.categories[i]  });
          }
  
        }

        yield put({ type: actions.SELECT_CURRENT_SUCESS, data: state.entities[entry._id] });
       
    
      } catch (e) {
        yield put({ type: actions.SELECT_CURRENT_FAILED, id: entry._id });
      }

    
  }
  
  function* deleteData(data) {
    try {
  
      const id = data._id;
      const res = yield call(axios.delete, '/api/product/'+data._id)
      const state = yield select(state => state)
      yield put({ type: actions.DELETE_SUCESS, data: data._id, state  });
      yield put({ type: actions.UPDATE_ENTRY, _id: id });
  
    } catch (e) {
      yield put({ type: actions.DELETE_ERROR, e });
    }
  }


export function* updateSaga({ products, product }) {
  yield localStorage.setItem(localDataName, JSON.stringify(products));
  yield put({
    type: actions.UPDATE,
    products,
    product
  });
}
export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.GET_LIST, getData),
    yield takeEvery(actions.UPDATE_ENTRY, updateEntry),
    yield takeEvery(actions.UPDATE_SAGA, updateSaga),
    yield takeEvery(actions.SAVE, saveData),
    yield takeEvery(actions.DELETE, deleteData),
    yield takeEvery(actions.SELECT_CURRENT, selectCurrent)
  ]);
}
