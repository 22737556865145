const DOCUMENT = 'PRICERULE_';

const actions = {
  GET_LIST: DOCUMENT+'GET_LIST',
  UPDATE: DOCUMENT+'UPDATE',
  UPDATE_SAGA: DOCUMENT+ 'UPDATE_SAGA',
  SELECT_CURRENT: DOCUMENT+'SELECT_CURRENT',
  SAVE: DOCUMENT+'SAVE',
  DELETE: DOCUMENT+'DELETE',
  DELETE_SUCESS: DOCUMENT+'DELETE_SUCESS',
  DELETE_ERROR: DOCUMENT+'DELETE_ERROR',
  CREATE_SUCESS: DOCUMENT+'CREATE_SUCESS',
  CREATE_ERROR: DOCUMENT+'CREATE_ERROR',
  UPDATE_SUCESS: DOCUMENT+'UPDATE_SUCESS',
  UPDATE_ERROR: DOCUMENT+'UPDATE_ERROR',
  TOGGLE_MODAL: DOCUMENT+'TOGGLE_MODAL',

  initData: () => { return { type: actions.GET_LIST } },
  
  selectCurrent: _id => {return { type: actions.SELECT_CURRENT, _id }},
  toggleModal: (data = null) => ({
    type: actions.TOGGLE_MODAL,
    payload: { data },
  }),
  onSubmit: entry =>({ type: actions.SAVE, entry }),
  delete: _id =>({ type: actions.DELETE, _id }),
};
export default actions;
