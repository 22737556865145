import clone from 'clone';
import actions from './actions';

const initState = {
  list: [],
  init: false,
  current: {},
  currentProductId: '',
  editable: {},
  isNew: false,
  mode: 'view',
  modalActive: false,
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.UPDATE: {
      const current = action.entry
        ? action.entry
        : state.current;
      return {
        ...state,
        list: action.list,
        current: clone(current),
        currentProduct: action.currentProductId,
        init: true,
        isNew: false,
        enableEditView: false
      };
    }
    case actions.SELECT_CURRENT: {
      const list = state.list;
      const index = list.map(entry => entry._id).indexOf(action._id);
      const isNew = index === -1;
      const current = isNew
        ? {
            _id: action._id,
            number: `#${action._id}`,
            key: action._id,
            //...newInvoice
          }
        : list[index];
      const enableEditView = isNew;
      return {
        ...state,
        current,
        isNew,
        enableEditView,
        editable: clone(current)
      };
    }
    case actions.TOGGLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        article: action.data == null ? initState.current : action.data,
      };
    default:
      return state;
  }
}
