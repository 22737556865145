import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import productSagas from './product/saga';
import priceSagas from './price/saga';
import invoicesSagas from './invoice/saga';
import priceRuleSagas from './priceRule/saga';
import priceSourceSagas from './priceSource/saga';
import storeSagas from './store/saga';
import offerSagas from './offer/saga';
import categorySagas from './category/saga';
import platformSagas from './platform/saga';
import userSagas from './user/saga';
import merchantSagas from './merchant/saga';
export default function* rootSaga(getState) {
  yield all([
    authSagas(), 
	invoicesSagas(),
  productSagas(),
  priceSagas(),
  priceRuleSagas(),
  offerSagas(),
  categorySagas(),
  priceSourceSagas(),
  platformSagas(),
  storeSagas(),
  userSagas(),
  merchantSagas()
  ]);
}
