
import actions from './actions';


const initState = {
  entries: [],
  init: false,
  tree: []
};

//function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

//function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }


function parseSimpleTreeData(treeData) {
 
  var keyNodes = {};
  var rootNodeList = []; // Fill in the map

  var nodeList = Object.keys(treeData).map(function (key) {

    var entry = treeData[key];
    var node = {
      pId: entry.parentId,
      id: entry._id,
      title: entry.name,
      value: entry._id,
      selectable: entry.parentId !== null,
      loaded: entry.loaded
    }

    keyNodes[key] = node;
    node.key = node.key || key;
    return node;
  }); // Connect tree

 

  nodeList.forEach(function (node) {
   
    var parentKey = node.pId
    var parent = keyNodes[parentKey]; // Fill parent

    if (node.loaded)
    {
      var children = node.children || []
      node.isLeaf = children.length === 0 && node.loaded
    }
     

    if (parent) {
      parent.children = parent.children || [];
      parent.children.push(node);
      parent.isLeaf = false;
    } // Fill root tree node


    if (parentKey === null || !parent) {
      rootNodeList.push(node);
    }
  });
  return rootNodeList;
}

// element.pId = element.parentId
// element.id = element._id
// element.title = element.name
// element.value = element.id

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.UPDATE_ROOT: {

      
      action.list.forEach(element => {
        state.entries[element._id] = element;
      });

      return {
        ...state,
        tree:parseSimpleTreeData(state.entries),
        init: true
      };
    }
    case actions.UPDATE_CHILDREN: {

      action.list.forEach(element => {

        state.entries[element._id] = element;
      });

      const e = state.entries[action._id];

   
      if (e)
      {
        e.loaded = true;
      }
     
      return {
        ...state,
        tree:parseSimpleTreeData(state.entries),
      };
    }
    case actions.UPDATE_ENTRY: {

      action.list.forEach(element => {
        state.entries[element._id] = element;
      });

      return {
        ...state,
        tree:parseSimpleTreeData(state.entries),
      };
    }
    default:
      return state;
  }
}
