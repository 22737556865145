import antdEn from 'antd/lib/locale-provider/de_DE';
import appLocaleData from 'react-intl/locale-data/en';
import deMessages from '../locales/de_DE.json';
// import { getKeys, getValues } from '../conversion';
// getValues(enMessages);

const DeLang = {
  messages: {
    ...deMessages,
  },
  antd: antdEn,
  locale: 'de-DE',
  data: appLocaleData,
};
export default DeLang;
